import React from 'react';

const TermsOfService = () => {
    return (
        <div className="terms-container">
            <header className="header">
                <a href="https://myfnb.ca/">
                    <img src="https://myfnb.ca/images/Logo/Logo_MyFnB.jpg" alt="MyFNB Logo" className="terms-logo" />
                </a>
                <div className="banner">
                    <h1 className="banner-title">Terms of Service</h1>
                </div>
            </header>

            <section className="content">
                <p className="effective-date">Effective Date: July 18, 2024</p>

                <h2>Welcome to MyFNB!</h2>
                <p>
                    These Terms of Service (“Terms”) govern your access to and use of our website, applications, and other services (collectively, the “Services”) provided by MyFNB. (“MyFNB,” “we,” “our,” or “us”). By accessing or using our Services, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Services.
                </p>

                <h2>1. Acceptance of Terms</h2>
                <p>
                    By creating an account, accessing, or using our Services, you agree to these Terms and our Privacy Policy. If you are using our Services on behalf of a company or other entity, you represent and warrant that you have the authority to bind that entity and its affiliates to these Terms.
                </p>

                <h2>2. Changes to Terms</h2>
                <p>
                    We may modify these Terms at any time. If we make changes, we will notify you by posting the new Terms on our website or through other appropriate means. Your continued use of our Services after any changes constitutes acceptance of the updated Terms.
                </p>

                <h2>3. Eligibility</h2>
                <p>
                    To use our Services, you must be at least 18 years old. By using our Services, you represent and warrant that you meet this age requirement.
                </p>

                <h2>4. User Accounts</h2>
                <p>
                    To access certain features of our Services, you may be required to create an account. You agree to:
                    <ul>
                        <li>Provide accurate, current, and complete information during the registration process.</li>
                        <li>Maintain the security of your account by protecting your password and restricting access to your account.</li>
                        <li>Notify us immediately of any unauthorized use of your account or other breach of security.</li>
                        <li>Take responsibility for all activities that occur under your account.</li>
                    </ul>
                </p>

                <h2>5. Services</h2>
                <p>
                    MyFNB provides a platform for job seekers and employers to connect. The Services include:
                    <ul>
                        <li>Job Posting: Employers can post job opportunities, manage job listings, and review applications.</li>
                        <li>Job Search: Job seekers can search for job openings, apply for jobs, and manage their applications.</li>
                        <li>Additional Features: Other features may include resume creation, job alerts, and application tracking.</li>
                    </ul>
                </p>

                <h2>6. Use of Services</h2>
                <p>
                    You agree to use our Services only for lawful purposes and in accordance with these Terms. You must not:
                    <ul>
                        <li>Violate any applicable laws or regulations.</li>
                        <li>Post or transmit any content that is illegal, harmful, threatening, abusive, harassing, defamatory, obscene, or otherwise objectionable.</li>
                        <li>Engage in any conduct that disrupts or interferes with the Services or the servers and networks connected to the Services.</li>
                        <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with any person or entity.</li>
                    </ul>
                </p>

                <h2>7. Intellectual Property</h2>
                <p>
                    All content, features, and functionality of our Services are owned by MyFNB or our licensors and are protected by Canadian and international intellectual property laws. You may not reproduce, distribute, modify, or create derivative works from any part of our Services without our prior written consent.
                </p>

                <h2>8. Content Submission</h2>
                <p>
                    By submitting content to our Services, you grant MyFNB a perpetual, irrevocable, non-exclusive, royalty-free license to use, reproduce, modify, publish, and distribute such content. You represent and warrant that you have the rights to grant this license and that the content does not infringe on any third-party rights.
                </p>

                <h2>9. Termination</h2>
                <p>
                    We reserve the right to suspend or terminate your access to our Services at our discretion, with or without cause, and with or without notice. If you breach these Terms, we may terminate your account immediately.
                </p>

                <h2>10. Disclaimers</h2>
                <p>
                    Our Services are provided on an “as-is” and “as-available” basis. We make no warranties or representations about the accuracy, reliability, completeness, or timeliness of the Services. We disclaim all implied warranties, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                </p>

                <h2>11. Limitation of Liability</h2>
                <p>
                    To the fullest extent permitted by law, MyFNB and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or data, arising from or related to your use of our Services.
                </p>

                <h2>12. Indemnification</h2>
                <p>
                    You agree to indemnify, defend, and hold harmless MyFNB and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising from your use of the Services, your violation of these Terms, or your violation of any rights of another party.
                </p>

                <h2>13. Governing Law</h2>
                <p>
                    These Terms are governed by and construed in accordance with the laws of the Province of Ontario and the federal laws of Canada applicable therein, without regard to its conflict of laws principles. Any disputes arising from or related to these Terms or the Services shall be subject to the exclusive jurisdiction of the courts located in Toronto, Ontario.
                </p>

                <h2>14. Dispute Resolution</h2>
                <p>
                    Any disputes arising from or related to these Terms or the Services shall be resolved through binding arbitration in accordance with the rules of the Arbitration Act, 1991 (Ontario). The arbitration will be conducted in Toronto, Ontario, and the arbitrator’s decision shall be final and binding.
                </p>

                <h2>15. Third-Party Links</h2>
                <p>
                    Our Services may contain links to third-party websites or services that are not owned or controlled by MyFNB. We do not endorse or assume responsibility for any third-party websites or services. You access these links at your own risk.
                </p>

                <h2>16. Feedback</h2>
                <p>
                    We welcome feedback, comments, and suggestions for improving our Services. However, any feedback you provide will be considered non-confidential and non-proprietary, and MyFNB will be free to use such feedback without any obligation to you.
                </p>

                <h2>17. Privacy Policy</h2>
                <p>
                    Your use of our Services is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information. By using our Services, you agree to the terms of our Privacy Policy.
                </p>

                <h2>18. Entire Agreement</h2>
                <p>
                    These Terms, together with our Privacy Policy and any other legal notices published by MyFNB, constitute the entire agreement between you and MyFNB concerning the Services and supersede any prior agreements or understandings.
                </p>

                <h2>19. Contact Information</h2>
                <p>
                    If you have any questions or concerns about these Terms or our Services, please contact us at:
                </p>
                <address>
                    {/* MyFNB<br /> */}
                    <p><b>Email:</b> admin@myfnb.com<br /></p>
                </address>
            </section>

            <style jsx>{`
                .terms-container {
                    max-width: 1000px;
                    margin: 0 auto;
                    padding: 20px;
                    font-family: 'Roboto', sans-serif;
                    line-height: 1.6;
                    background-color: #f8f9fa;
                    color: #343a40;
                }

                .header {
                    text-align: center;
                    margin-bottom: 20px;
                }

                .terms-logo {
                    max-width: 200px;
                    margin: 0 auto;
                }

                .banner {
                    background-color: #000000;
                    color: #ffffff;
                    text-align: center;
                    padding: 15px;
                    border-radius: 8px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    margin-bottom: 20px;
                }

                .banner-title {
                    margin: 0;
                    font-size: 2rem;
                    font-weight: 700;
                }

                .content h2 {
                    font-size: 1.2rem;
                    margin-top: 20px;
                    color: #000000;
                    border-bottom: 2px solid #e2e6ea;
                    padding-bottom: 5px;
                }

                .content p {
                    margin: 10px 0;
                }

                .content ul {
                    list-style: disc;
                    margin: 10px 0 10px 20px;
                }

                .effective-date {
                    font-style: italic;
                    margin-bottom: 15px;
                    color: #6c757d;
                }

                address {
                    font-style: normal;
                    line-height: 1.4;
                }

                address br {
                    content: "";
                    margin-bottom: 5px;
                }

                /* Responsive Design */
                @media (max-width: 768px) {
                    .terms-logo {
                        max-width: 150px;
                    }

                    .banner-title {
                        font-size: 1.5rem;
                    }

                    .content h2 {
                        font-size: 1rem;
                    }
                }

                @media (max-width: 480px) {
                    .terms-container {
                        padding: 10px;
                    }

                    .banner {
                        padding: 10px;
                    }

                    .banner-title {
                        font-size: 1.2rem;
                    }

                    .content h2 {
                        font-size: 0.9rem;
                    }

                    .content p, .content ul {
                        font-size: 0.9rem;
                    }
                }
            `}</style>
        </div>
    );
};

export default TermsOfService;