import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function Copyright() {
    return (
        <React.Fragment>
            {'© '}
            MyFNB {new Date().getFullYear()}
        </React.Fragment>
    );
}

const iconStyle = {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    '&:hover': {
        opacity: 0.8,
    },
};

export default function AppFooter() {
    return (
        <Box component="footer" sx={{ bgcolor: '#f8f9fa', py: 6 }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Follow Us
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box component="a" href="https://www.instagram.com/myfnb_ca/" sx={iconStyle} target="_blank" rel="noopener noreferrer">
                                <img src="/images/icons8-instagram.svg" alt="Instagram" />
                            </Box>
                            <Box component="a" href="https://twitter.com/myfnb_ca" sx={iconStyle} target="_blank" rel="noopener noreferrer">
                                <img src="/images/twitter.svg" alt="Twitter" />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Legal
                        </Typography>
                        <Box component="ul" sx={{ m: 0, p: 0, listStyle: 'none' }}>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Link href="/terms-of-service" variant="body2">
                                    Terms of Service
                                </Link>
                            </Box>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Link href="/privacy-policy" variant="body2">
                                    Privacy Policy
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography variant="body2">
                            MyFNB
                            <br />
                            Email: <Link href="mailto:support@myfnb.com">admin@myfnb.com</Link>
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Copyright />
                </Box>
            </Container>
        </Box>
    );
}