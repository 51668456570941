import React from "react";

const NotFound = () => {
    return (
        <div id="wrapper">
            <center>
                <img src="https://img.freepik.com/free-vector/404-error-design-with-donut_23-2147739030.jpg?w=740&t=st=1699996843~exp=1699997443~hmac=0137da8cee72d2571b686a45762ab2bcfd8b4c6df46827c85bafa18f5a6c35ec" alt="Page not found" />
            </center>
        </div >
    );
};
export default NotFound;