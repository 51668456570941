import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '../component/Button';
import Typography from './Typography';
import ProductHeroLayout from './ProductHeroLayout';

export default function ProductHero() {
    const [jobTitle, setJobTitle] = useState('');
    const [jobLocation, setJobLocation] = useState('');
    const [isLoggedInAsEmployer, setIsLoggedInAsEmployer] = useState(false);

    useEffect(() => {
        // Check if the user is logged in as an employer
        const token = localStorage.getItem('token');
        if (token) {
            // Decode the token to extract user information (role)
            const decodedToken = decodeToken(token);
            // Check if the user role is employer
            if (decodedToken.role === 'employer') {
                setIsLoggedInAsEmployer(true);
            }
        }
    }, []);

    const handleSearch = () => {
        // Build the search query
        const queryParams = {};
        if (jobTitle) {
            queryParams.jobTitle = jobTitle;
        }
        if (jobLocation) {
            queryParams.jobLocation = jobLocation;
        }

        // Redirect to the job listing page with the search query parameters
        window.location.href = `/all-jobs?${new URLSearchParams(queryParams).toString()}`;
    };

    // Function to decode JWT token
    const decodeToken = (token) => {
        // Decode the token
        const tokenParts = token.split('.');
        const decodedToken = JSON.parse(atob(tokenParts[1]));
        return decodedToken;
    };

    return (
        <>
            <link rel="preload" href="/images/home.jpg" as="image" />
            <ProductHeroLayout
                sxBackground={{
                    backgroundImage: 'url(/images/home.jpg)',
                    backgroundColor: '#7fc7d9',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: "100vh",
                    transition: 'background-image 0.5s ease-in-out',
                }}
            >
                <Typography color="inherit" align="center" variant="h5" marked="center"
                    sx={{
                        mt: 2,
                        mb: 2,
                        display: 'flex',
                        color: 'white',
                        fontSize: '2rem',
                    }}
                >
                    Savour Opportunities, Toast to Careers
                </Typography>
                <Typography
                    color="inherit"
                    align="center"
                    variant="h5"
                    sx={{
                        mt: 2,
                        mb: 4,
                        display: 'flex',
                        color: 'white',
                        fontSize: '1.4rem',
                    }}
                >
                    Where Talent Meets Opportunity in the Food and Beverage World
                </Typography>
                {!isLoggedInAsEmployer && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <TextField
                            placeholder="What job are you looking for?"
                            id="outlined-basic"
                            label="Job Title, Keywords or Company"
                            variant="filled"
                            onChange={(e) => setJobTitle(e.target.value)}
                            sx={{
                                width: '40%',
                                marginRight: '1rem',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            placeholder="Where do you want to work?"
                            id="outlined-basic"
                            label="Location"
                            variant="filled"
                            onChange={(e) => setJobLocation(e.target.value)}
                            sx={{
                                width: '40%',
                                marginRight: '1rem',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                            sx={{
                                width: '20%',
                                borderRadius: '5px',
                                backgroundColor: '#ff3366',
                            }}
                        >
                            Search
                        </Button>
                    </div>
                )}
            </ProductHeroLayout>
        </>
    );
}