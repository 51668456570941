import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container">
            <header className="header">
                <a href="https://myfnb.ca/">
                    <img src="https://myfnb.ca/images/Logo/Logo_MyFnB.jpg" alt="MyFNB Logo" className="privacy-logo" />
                </a>
                <div className="banner">
                    <h1 className="banner-title">Privacy Policy</h1>
                </div>
            </header>

            <section className="content">
                <h2>Introduction</h2>
                <p>
                    Welcome to MyFNB.ca ("we," "us," "our"). We are committed to protecting your privacy and ensuring
                    that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines
                    our practices concerning the collection, use, and disclosure of personal information under the Personal
                    Information Protection and Electronic Documents Act (PIPEDA) and other relevant Canadian privacy
                    laws.
                </p>

                <h2>Information We Collect</h2>
                <p>
                    We collect information from you when you use our website, create an account, apply for jobs, or
                    interact with our services. The types of information we collect include:
                </p>
                <ul>
                    <li><strong>Personal Identification Information:</strong> Name, email address, phone number, postal address, and other contact details.</li>
                    <li><strong>Professional Information:</strong> Resume, cover letter, job history, education, skills, and references.</li>
                    <li><strong>Usage Data:</strong> IP address, browser type, operating system, referring URLs, pages visited, and the date and time of your visit.</li>
                </ul>

                <h2>How We Use Your Information</h2>
                <p>We use the information we collect to:</p>
                <ul>
                    <li>Provide and manage our services, including job postings and applications.</li>
                    <li>Communicate with you about your account, job applications, and updates.</li>
                    <li>Improve our website, services, and user experience.</li>
                    <li>Protect the security and integrity of our website.</li>
                    <li>Comply with legal and regulatory requirements.</li>
                </ul>

                <h2>Sharing Your Information</h2>
                <p>We may share your personal information with:</p>
                <ul>
                    <li><strong>Employers and Recruiters:</strong> To facilitate job applications and recruitment processes.</li>
                    <li><strong>Service Providers:</strong> Third-party vendors who assist us in operating our website and providing our services.</li>
                    <li><strong>Legal Authorities:</strong> When required by law, regulation, or legal process.</li>
                </ul>

                <h2>Data Retention</h2>
                <p>
                    We retain your personal information for as long as necessary to provide our services, comply with legal
                    obligations, resolve disputes, and enforce our agreements. When your personal information is no longer
                    required, we will securely delete or anonymize it.
                </p>

                <h2>Your Rights</h2>
                <p>Under Canadian privacy laws, you have the right to:</p>
                <ul>
                    <li>Access your personal information and request corrections.</li>
                    <li>Withdraw consent for the collection, use, and disclosure of your personal information.</li>
                    <li>File a complaint with the Office of the Privacy Commissioner of Canada if you believe your
                        privacy rights have been violated.</li>
                </ul>

                <h2>Security</h2>
                <p>
                    We implement appropriate technical and organizational measures to protect your personal information
                    from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission
                    over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                </p>

                <h2>Cookies and Tracking Technologies</h2>
                <p>
                    We use cookies and similar tracking technologies to enhance your experience on our website. You can
                    manage your cookie preferences through your browser settings. For more information, please refer to
                    our Cookie Policy.
                </p>

                <h2>Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal
                    requirements. We will notify you of any significant changes by posting the updated policy on our
                    website and indicating the date of the latest revision.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact
                    us at:
                </p>
                <address>
                    <b>MyFNB.ca<br /></b>
                    <p><b>Email:</b> admin@myfnb.ca</p>
                </address>
            </section>

            <style jsx>{`
                .privacy-container {
                    max-width: 1000px;
                    margin: 0 auto;
                    padding: 20px;
                    font-family: 'Roboto', sans-serif;
                    line-height: 1.6;
                    background-color: #f8f9fa;
                    color: #343a40;
                }

                .header {
                    text-align: center;
                    margin-bottom: 20px;
                }

                .privacy-logo {
                    max-width: 200px;
                    margin: 0 auto;
                }

                .banner {
                    background-color: #000000;
                    color: #ffffff;
                    text-align: center;
                    padding: 15px;
                    border-radius: 8px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    margin-bottom: 20px;
                }

                .banner-title {
                    margin: 0;
                    font-size: 2rem;
                    font-weight: 700;
                }

                .content h2 {
                    font-size: 1.2rem;
                    margin-top: 20px;
                    color: #000000;
                    border-bottom: 2px solid #e2e6ea;
                    padding-bottom: 5px;
                }

                .content p {
                    margin: 10px 0;
                }

                .content ul {
                    list-style: disc;
                    margin: 10px 0 10px 20px;
                }

                address {
                    font-style: normal;
                    line-height: 1.4;
                }

                address br {
                    content: "";
                    margin-bottom: 5px;
                }

                /* Responsive Design */
                @media (max-width: 768px) {
                    .privacy-logo {
                        max-width: 150px;
                    }

                    .banner-title {
                        font-size: 1.5rem;
                    }

                    .content h2 {
                        font-size: 1rem;
                    }
                }

                @media (max-width: 480px) {
                    .privacy-container {
                        padding: 10px;
                    }

                    .banner {
                        padding: 10px;
                    }

                    .banner-title {
                        font-size: 1.2rem;
                    }

                    .content h2 {
                        font-size: 0.9rem;
                    }

                    .content p, .content ul {
                        font-size: 0.9rem;
                    }
                }
            `}</style>
        </div>
    );
};

export default PrivacyPolicy;