import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppAppBar from '../component/AppAppBar';
import AppForm from '../component/AppForm';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const VerifyEmailPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    console.log(message);

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                if (!token) {
                    throw new Error('Token is missing');
                }

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/verify_email?token=${token}`);

                // If email is verified successfully
                if (response.status === 200) {
                    const successMessage = response.data.message;
                    setMessage(successMessage);
                    toast.success(successMessage);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || 'Email verification failed';
                setMessage(errorMessage);
                if (errorMessage !== 'Email is already verified') {
                    toast.error(errorMessage);
                }
            }
        };

        verifyEmail();
    }, [token, navigate]);

    return (
        <React.Fragment>
            <AppAppBar />
            <AppForm>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Typography variant="h4" gutterBottom>
                        Email Verification
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {message}
                    </Typography>
                    {message === 'Email is already verified' && (
                        <Button variant="contained" color="primary" onClick={() => navigate('/Sign In')} style={{ marginTop: '20px' }}>
                            Sign In
                        </Button>
                    )}
                </div>
            </AppForm>
        </React.Fragment>
    );
};

export default VerifyEmailPage;