import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from './Typography';
import TextField from './TextField';
import Snackbar from './Snackbar';
import Button from './Button';
import axios from 'axios';

function ProductCTA() {
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [jobTitle, setJobTitle] = React.useState('');
    const [location, setLocation] = React.useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscribe`, { email, jobTitle, location });
            if (response.status === 201) {
                setSnackbarMessage('Successfully subscribed. Stay updated with the latest job listings!');
                setSnackbarOpen(true);
                setEmail('');
                setJobTitle('');
                setLocation('');
            } else {
                setSnackbarMessage(`Failed to subscribe: ${response.data.message}`);
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(`${error.response.data.message}`);
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <Container component="section" sx={{ mt: 10, display: 'flex' }}>
            <Grid container>
                <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            bgcolor: 'warning.main',
                            py: 8,
                            px: 3,
                        }}
                    >
                        <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400 }}>
                            <Typography variant="h3" component="h2" gutterBottom>
                                Bite into Job Alerts
                            </Typography>
                            <Typography variant="h5">
                                Get tailored job alerts, straight to your plate.
                            </Typography>
                            <TextField
                                noBorder
                                placeholder="Your email"
                                variant="standard"
                                sx={{ width: '100%', mt: 3, mb: 2 }}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError('');
                                }}
                                error={!!emailError}
                                helperText={emailError}
                                required
                            />
                            <TextField
                                noBorder
                                placeholder="Job Title"
                                variant="standard"
                                sx={{ width: '100%', mb: 2 }}
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                                required
                            />
                            <TextField
                                noBorder
                                placeholder="Location"
                                variant="standard"
                                sx={{ width: '100%', mb: 2 }}
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                required
                            />
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                sx={{ width: '100%' }}
                            >
                                Keep me updated
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: -67,
                            left: -67,
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            background: 'url(/images/productCTAImageDots.png)',
                        }}
                    />
                    <Box
                        component="img"
                        src="https://btl-company.com/wp-content/uploads/2019/12/Depositphotos_174977644_xl-201-1024x682.jpg"
                        alt="call to action"
                        sx={{
                            position: 'absolute',
                            top: -28,
                            left: -28,
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            maxWidth: 600,
                        }}
                    />
                </Grid>
            </Grid>
            <Snackbar
                open={snackbarOpen}
                closeFunc={handleCloseSnackbar}
                message={snackbarMessage}
            />
        </Container>
    );
}

export default ProductCTA;