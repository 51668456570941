import * as React from 'react';
import FormButton from '../form/FormButton';
import Container from '@mui/material/Container';
import Typography from '../component/Typography';
import AppAppBar from '../component/AppAppBar';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import withRoot from '../withRoot';

function ContactUsForm() {
    const [buttonText, setButtonText] = React.useState('Send Message');
    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = React.useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!formData.name.trim()) {
            errors.name = 'Name is required';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            errors.email = 'Invalid email address';
        }
        if (!formData.message.trim()) {
            errors.message = 'Message is required';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try {
            setButtonText('Processing...');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact-us`, formData);
            if (response.status === 200) {
                // Show success message
                alert('Your message has been sent successfully!');
                // Clear form data
                setFormData({ name: '', email: '', message: '' });
            } else {
                alert('Failed to send message. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while sending your message. Please try again later.');
        } finally {
            // Reset button text
            setButtonText('Send Message');
        }
    };

    return (
        <React.Fragment>
            <AppAppBar />
            <Container component="section" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}>
                <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
                    Contact Us
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        name="name"
                        label="Name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={errors.name}
                        helperText={errors.name && errors.name}
                    />
                    <TextField
                        name="email"
                        label="Email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={errors.email}
                        helperText={errors.email && errors.email}
                    />
                    <TextField
                        name="message"
                        label="Message"
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={errors.message}
                        helperText={errors.message && errors.message}
                    />
                    <FormButton
                        sx={{ mt: 3, mb: 2 }}
                        size="large"
                        color="secondary"
                        fullWidth
                        type="submit"
                    >
                        {buttonText}
                    </FormButton>
                </form>
            </Container>
        </React.Fragment>
    );
}

export default withRoot(ContactUsForm);