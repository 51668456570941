import React, { useState, useRef, useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '../component/Paper';
import AppAppBar from '../component/AppAppBar';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import RFTextField from '../form/RFTextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormButton from '../form/FormButton';
import FormFeedback from '../form/FormFeedback';
import withRoot from '../withRoot';
import JobRequirementsCheckboxGroup from '../component/JobRequirementsCheckboxGroup';
import WorkScheduleCheckboxGroup from '../component/WorkScheduleCheckboxGroup';
import SupplementalPayCheckboxGroup from '../component/SupplementalPayCheckboxGroup';
import BenefitsOfferedSection from '../component/BenefitsOfferedCheckbox';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdditionalQuestions from '../component/AdditionalQuestions';

const EditJobPostingForm = () => {
    const navigate = useNavigate();
    const { jobId } = useParams();
    const [sent, setSent] = useState(false);
    const [showWageRate, setShowWageRate] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const [consentToAddQuestions, setConsentToAddQuestions] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [address, setAddress] = useState('');
    const [addressDetails, setAddressDetails] = useState({});
    const autocompleteRef = useRef(null);

    useEffect(() => {
        // Initialize Google Places Autocomplete
        const autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            { types: ['address'] }
        );

        // Handle place selection
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (place.formatted_address) {
                setAddress(place.formatted_address);

                // Extract address components and coordinates
                const components = place.address_components.reduce((acc, component) => {
                    if (component.types.includes('street_number') || component.types.includes('route')) {
                        acc.street = `${acc.street || ''} ${component.long_name}`.trim();
                    }
                    if (component.types.includes('locality')) {
                        acc.city = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        acc.province = component.short_name;
                    }
                    if (component.types.includes('country')) {
                        acc.country = component.long_name;
                    }
                    if (component.types.includes('postal_code')) {
                        acc.postalCode = component.long_name;
                    }
                    return acc;
                }, {});

                // Add latitude and longitude
                const lat = place.geometry?.location?.lat();
                const lng = place.geometry?.location?.lng();

                setAddressDetails({
                    ...components,
                    lat,
                    long: lng
                });
            }
        });

        autocompleteRef.current = autocomplete;
    }, []);

    useEffect(() => {
        // Fetch existing job details when the component mounts
        const fetchJobDetails = async () => {
            try {
                // Retrieve the token from localStorage
                const token = localStorage.getItem('token');

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_single_job/${jobId}`, {
                    headers: { Authorization: `${token}` },
                });

                if (response.status === 200) {
                    const jobData = response.data.job;

                    // Convert preferredStartDate to the required format if it exists
                    if (jobData.preferredStartDate) {
                        jobData.preferredStartDate = new Date(jobData.preferredStartDate)
                            .toISOString()
                            .split('T')[0]; // Extract 'YYYY-MM-DD'
                    }

                    setInitialValues(jobData);
                    setShowWageRate(jobData.showWageRate || false);

                    // Fetch employer profile to get the address
                    const profileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/get_single_employee`, {
                        headers: { Authorization: `${token}` },
                    });

                    if (profileResponse.status === 200) {
                        const location = profileResponse.data.employerWithUserDetails.address;
                        if (location) {
                            const formattedAddress = `${location.street}, ${location.city}, ${location.province}, ${location.country} ${location.postalCode}`;
                            setAddress(formattedAddress);
                            setAddressDetails(location);
                        }
                    }

                    // // Pre-fill address input with existing job location
                    // const location = response.data.job.jobLocation;
                    // if (location) {
                    //     const formattedAddress = `${location.street}, ${location.city}, ${location.province}, ${location.country} ${location.postalCode}`;
                    //     setAddress(formattedAddress);
                    //     setAddressDetails(location);
                    // }

                    // Check if selected questions exist and update the switch state accordingly
                    if (jobData.selectedQuestions && jobData.selectedQuestions.length > 0) {
                        setConsentToAddQuestions(true);
                        setSelectedQuestions(jobData.selectedQuestions);
                    }
                }
            } catch (error) {
                console.error(error);
                toast.error('An error occurred while retrieving job details!');
            }
        };

        fetchJobDetails();
    }, [jobId]);

    const validate = (values) => {
        const errors = {};
        if (values.showWageRate) {
            if (!values.payAmount) {
                errors.payAmount = 'Pay Amount is required';
            }
            if (!values.payRate) {
                errors.payRate = 'Pay Rate is required';
            }
        }

        return errors;
    };

    const handleSubmit = async (values) => {
        try {
            if (!values.jobTitle || !values.jobDescription || !values.jobType || !address) {
                toast.error('Please fill in all required fields before submitting the form.');
                return;
            }

            // Check additional validation
            if (values.hoursPerWeek !== undefined && values.hoursPerWeek < 0) {
                toast.error('Hours per week must be a positive number');
                return;
            }

            // Retrieve the token from localStorage
            const token = localStorage.getItem('token');

            // Prepare payload including selected questions
            const payload = {
                ...values,
                showWageRate,
                selectedQuestions,
                jobLocation: addressDetails
            };

            // Make API request to update the job posting
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/update_job/${jobId}`, payload,
                { headers: { Authorization: `${token}` } }
            );

            if (response.status === 200) {
                toast.success(response.data.message);
                setSent(true);

                setTimeout(() => {
                    navigate(`/job-listing`);
                }, 1500);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            const errorData = error.response.data;
            console.log(errorData.messages);
            toast.error(errorData.messages);
        }
    };

    const RichTextEditor = ({ input, label, meta: { touched, error } }) => {
        const [editorState, setEditorState] = useState(() => {
            const contentState = input.value ? convertFromRaw(JSON.parse(input.value)) : null;
            return contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty();
        });

        useEffect(() => {
            const contentState = input.value ? convertFromRaw(JSON.parse(input.value)) : null;
            const newEditorState = contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty();
            setEditorState(EditorState.moveFocusToEnd(newEditorState));
        }, [input.value]);

        const handleChange = (state) => {
            setEditorState(state);
            const contentState = state.getCurrentContent();
            const rawContentState = convertToRaw(contentState);
            input.onChange(JSON.stringify(rawContentState));
        };

        const toolbarOptions = {
            options: ['inline', 'list', 'textAlign'],
            inline: {
                options: ['bold', 'italic', 'underline'],
                className: 'inline-text-toolbar',
            },
            list: {
                options: ['unordered', 'ordered'],
            }
        };

        return (
            <div style={{ backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '5px' }}>
                <label>{label}</label>
                <div style={{ border: '2px solid #ccc', borderRadius: '5px', minHeight: '300px', padding: '5px' }}>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbar-class"
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbar={toolbarOptions}
                        onEditorStateChange={handleChange}
                    />
                </div>
                {touched && error && <span>{error}</span>}
            </div>
        );
    };

    return (
        <React.Fragment>
            <AppAppBar />
            <Box
                sx={{
                    display: 'flex',
                    backgroundImage: 'url(/images/productCurvyLines.png)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '70vh',
                    backgroundColor: '#f5f5f5',
                }}
            >
                <Container maxWidth="md">
                    <Paper
                        background="light"
                        sx={{ py: { xs: 4, md: 5 }, px: { xs: 3, md: 6 } }}
                    >
                        <Typography variant="h4" gutterBottom marked="center" align="center">
                            Edit Job Posting
                        </Typography>

                        <Form
                            onSubmit={handleSubmit}
                            initialValues={initialValues}
                            subscription={{ submitting: true }}
                            validate={validate}
                        >
                            {({ handleSubmit: handleSubmit2, submitting }) => (
                                <Box component="form" onSubmit={handleSubmit2} noValidate>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                label="Job Title"
                                                name="jobTitle"
                                                fullWidth
                                                autoFocus
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Field
                                        component={RichTextEditor}
                                        disabled={submitting || sent}
                                        label={
                                            <>
                                                Job Description <span style={{ color: 'red' }}>*</span>
                                            </>
                                        }
                                        name="jobDescription"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        required
                                    />
                                    <Typography variant="inherit" gutterBottom>
                                        Location <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <input
                                                id="autocomplete"
                                                type="text"
                                                placeholder="Enter job location"
                                                value={address}
                                                readOnly
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', backgroundColor: '#f5f5f5' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        {/* hoursPerWeek */}
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                label="Hours Per Week"
                                                name="hoursPerWeek"
                                                fullWidth
                                                type="text" // Change type to text to accept range inputs
                                                validate={value => {
                                                    const regex = /^(\d+|\d+-\d+)$/;
                                                    return regex.test(value) ? undefined : 'Invalid format. Enter a number or range (e.g., 15 or 15-20)';
                                                }}
                                                InputProps={{ inputProps: { min: 0 } }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h5" gutterBottom>
                                        Required Training/Experience
                                    </Typography>
                                    <JobRequirementsCheckboxGroup />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                label="Experience"
                                                name="experience"
                                                fullWidth
                                                select
                                                SelectProps={{ native: true }}
                                            >
                                                <option value="">Select</option>
                                                <option value="1-3">1-3 Years</option>
                                                <option value="3-5">3-5 Years</option>
                                                <option value="5-7">5-7 Years</option>
                                                <option value="7-10">7-10 Years</option>
                                                <option value="10+">10+ Years</option>
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                label="Language Requirements"
                                                name="languageRequirements"
                                                type="text"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* Job Type */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                label="Job Type"
                                                name="jobType"
                                                fullWidth
                                                select
                                                SelectProps={{ native: true }}
                                                required
                                            >
                                                <option value="">Select</option>
                                                <option value="fullTime">Full-time</option>
                                                <option value="partTime">Part-time</option>
                                                <option value="internship">Internship</option>
                                                <option value="casual">Casual</option>
                                                <option value="seasonal">Seasonal</option>
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                name="preferredStartDate"
                                                label="Preferred Start Date"
                                                type="date"
                                                fullWidth
                                            >
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h6" color="primary" gutterBottom style={{ marginTop: 10 }}>
                                                Work Schedule
                                            </Typography>
                                            <WorkScheduleCheckboxGroup />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                name="numberOfPositions"
                                                label="Number of Positions"
                                                type="number"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                fullWidth
                                            >
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                name="applicationDeadline"
                                                label="Application Deadline"
                                                type="date"
                                                fullWidth
                                            ></Field>
                                        </Grid>
                                    </Grid>
                                    {/* Pay Rate Details */}
                                    <Typography variant="h6" gutterBottom>
                                        Pay Rate
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={showWageRate}
                                                        onChange={(e) => setShowWageRate(e.target.checked)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Show Pay Rate"
                                            />
                                        </Grid>
                                        {showWageRate && (
                                            <>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        component={RFTextField}
                                                        disabled={submitting || sent}
                                                        label="Pay Amount"
                                                        name="payAmount"
                                                        fullWidth
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        component={RFTextField}
                                                        disabled={submitting || sent}
                                                        label="Pay Rate"
                                                        name="payRate"
                                                        fullWidth
                                                        select
                                                        SelectProps={{ native: true }}
                                                        required
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="perHour">Per Hour</option>
                                                        <option value="perYear">Per Year</option>
                                                    </Field>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant='h6' style={{ marginTop: '20px' }}>
                                                Supplemental Pay
                                            </Typography>
                                            <SupplementalPayCheckboxGroup />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h6" gutterBottom>
                                        Benefits Offered
                                    </Typography>
                                    <BenefitsOfferedSection />
                                    {/* Communication Settings */}
                                    {/* <Typography variant="h5" gutterBottom>
                                        Communication Settings
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                fullWidth
                                                label="Add Emails"
                                                name="communicationEmail"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                fullWidth
                                                label="Add Phone Numbers"
                                                name="communicationPhone"
                                            />
                                        </Grid>
                                    </Grid> */}
                                    <AdditionalQuestions
                                        jobId={jobId}
                                        consentToAddQuestions={consentToAddQuestions}
                                        setConsentToAddQuestions={setConsentToAddQuestions}
                                        setSelectedQuestions={setSelectedQuestions}
                                        selectedQuestions={selectedQuestions}
                                    />

                                    <FormSpy subscription={{ submitError: true }}>
                                        {({ submitError }) =>
                                            submitError ? (
                                                <FormFeedback error sx={{ mt: 2 }}>
                                                    {submitError}
                                                </FormFeedback>
                                            ) : null
                                        }
                                    </FormSpy>
                                    <FormButton
                                        sx={{ mt: 2, mb: 2 }}
                                        disabled={submitting}
                                        color="secondary"
                                        fullWidth
                                    >
                                        {submitting ? 'In progress…' : 'Update Job Posting'}
                                    </FormButton>
                                </Box>
                            )}
                        </Form>
                    </Paper>
                </Container>
            </Box>
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRoot(EditJobPostingForm);