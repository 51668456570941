import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppAppBar from '../component/AppAppBar';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import axios from 'axios';
import withRoot from '../withRoot';
import CircularProgress from '@mui/material/CircularProgress';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardContent from '@mui/material/CardContent';
import JobDetailsDialog from '../component/JobDetailsDialog';

const MyJobs = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedJob, setSelectedJob] = useState(null);

    useEffect(() => {
        const fetchAppliedJobs = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/my-jobs`, {
                    headers: { Authorization: `${token}` }
                });
                setJobs(response.data);
                setLoading(false);
            } catch (error) {
                toast.error(
                    error?.response?.status === 401 ? "Please login again" : "Failed to load applied jobs",
                );
                console.error('Error fetching applied jobs:', error);
                setLoading(false); // Ensure loading state is updated even on error
            }
        };

        fetchAppliedJobs();
    }, []);

    const handleViewJobDetails = (job) => {
        setSelectedJob(job);
    };

    const handleCloseDialog = () => {
        setSelectedJob(null);
    };

    const formatJobDescription = (jobDescription) => {
        try {
            const parsedDescription = JSON.parse(jobDescription);
            const blocks = parsedDescription.blocks;
            return blocks.map((block) => block.text).join('\n');
        } catch (error) {
            console.error('Error parsing job description:', error);
            return '';
        }
    };

    if (loading) {
        // Render circular loading indicator while data is being fetched
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <React.Fragment>
            <AppAppBar />
            <Box
                sx={{
                    display: 'flex',
                    backgroundImage: 'url(/images/productCurvyLines.png)',
                    justifyContent: 'center',
                    minHeight: '90vh',
                }}
            >
                <Container maxWidth="md">
                    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h4" color="error" gutterBottom marked="center" align="center" sx={{ mt: 3, mb: 2 }}>
                            My Applied Jobs
                        </Typography>
                        {jobs.length === 0 ? (
                            <Paper
                                sx={{
                                    maxWidth: 400,
                                    borderRadius: 8,
                                    overflow: 'hidden',
                                    boxShadow: 8,
                                    margin: 'auto',
                                    marginTop: 10,
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        You haven't applied to any jobs yet.
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Start applying to jobs to see them listed here.
                                    </Typography>
                                </CardContent>
                            </Paper>
                        ) : (
                            jobs.map((job, index) => (
                                job ? ( // Check if job is not null
                                    <Paper key={index} elevation={3} sx={{ p: 3, mb: 3 }}>
                                        <Typography variant="h6" mb={0.5} sx={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: '20px', color: '#FF3366', fontWeight: 'bold' }}>
                                            {job.jobTitle}
                                        </Typography>
                                        <Typography variant="body1" mb={2} sx={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: '15px', color: '#666', fontWeight: 'normal' }}>
                                            {job.companyName || 'N/A'}
                                        </Typography>
                                        <Typography style={{ maxHeight: '3em', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                                            <strong>Description:</strong> {formatJobDescription(job.jobDescription)}
                                        </Typography>
                                        <Typography>
                                            <strong>Location:</strong> {job.jobLocation ? `${job.jobLocation.street}, ${job.jobLocation.city}, ${job.jobLocation.province},${job.jobLocation.country} ${job.jobLocation.postalCode}` : 'N/A'}
                                        </Typography>
                                        <Typography>
                                            <strong>Industry Type:</strong> {job.industryType ? job.industryType : 'N/A'}
                                        </Typography>
                                        <Typography><strong>Posted on:</strong> {new Date(job.createdAt).toLocaleDateString()}</Typography>
                                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Tooltip title="View Job Details" arrow>
                                                <IconButton color="primary" aria-label="view job details" onClick={() => handleViewJobDetails(job)}>
                                                    <Button variant="outlined" color="primary">
                                                        Show More
                                                    </Button>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Paper>
                                ) : null
                            ))
                        )}
                    </Box>
                </Container>
            </Box>
            <JobDetailsDialog
                selectedJob={selectedJob}
                handleCloseDialog={handleCloseDialog}
                showApplyButton={false}
            />
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRoot(MyJobs);