import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const AppliedUsersDialog = ({ open, handleClose, job }) => {
    const [selectedUser, setSelectedUser] = useState(null);

    // If job data is not yet available, show a loading spinner
    if (!job) {
        return (
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        );
    }

    // Construct the base URL for resumes
    const baseURL = process.env.REACT_APP_API_URL;

    const handleUserClick = (user) => {
        setSelectedUser(user);
    };

    const handleCloseDialog = () => {
        setSelectedUser(null);
    };

    const formatSchedule = (schedule) => {
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Day</TableCell>
                        <TableCell>Morning</TableCell>
                        <TableCell>Afternoon</TableCell>
                        <TableCell>Evening</TableCell>
                        <TableCell>Night</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(schedule).map((day) => (
                        <TableRow key={day}>
                            <TableCell>{day}</TableCell>
                            <TableCell>{schedule[day].Morning ? '✔️' : '❌'}</TableCell>
                            <TableCell>{schedule[day].Afternoon ? '✔️' : '❌'}</TableCell>
                            <TableCell>{schedule[day].Evening ? '✔️' : '❌'}</TableCell>
                            <TableCell>{schedule[day].Night ? '✔️' : '❌'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    };

    return (
        <React.Fragment>
            <Dialog open={open && !selectedUser} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>
                    Applied Users for {job.jobTitle}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>User</TableCell>
                                    <TableCell>Resume</TableCell>
                                    <TableCell>Availability</TableCell>
                                    <TableCell>Additional Questions Responses</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {job.appliedUsers.map((user) => (
                                    <TableRow key={user._id} onClick={() => handleUserClick(user)} style={{ cursor: 'pointer' }}>
                                        <TableCell>
                                            <Grid container alignItems="center">
                                                <Avatar src={`${baseURL}/uploads/images/${user.profilePicture}`} alt={`${user.firstName} ${user.lastName}`} />
                                                <Grid item style={{ marginLeft: '12px' }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {`${user.firstName} ${user.lastName}`}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {user.headline}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <a href={`${baseURL}/uploads/resumes/${user.resume}`} target="_blank" rel="noopener noreferrer">
                                                View Resume
                                            </a>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" color="textSecondary">
                                                Click to view schedule
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <ul>
                                                {user.additionalQuestionsResponses.map((response, index) => (
                                                    <li key={index}>
                                                        {response.question}: {response.response}
                                                    </li>
                                                ))}
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>

            {/* Dialog to display user's schedule */}
            <Dialog open={selectedUser !== null} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                <DialogTitle>
                    {`${selectedUser?.firstName} ${selectedUser?.lastName}'s Availability`}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseDialog}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {selectedUser && selectedUser.desiredSchedule && formatSchedule(selectedUser.desiredSchedule)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default AppliedUsersDialog;