import React, { useEffect, useState } from 'react';
import AppAppBar from '../component/AppAppBar';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function UnsubscribePage() {
    const { email } = useParams();
    const [unsubscribeStatus, setUnsubscribeStatus] = useState('');

    useEffect(() => {
        const unsubscribeUser = async () => {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_API_URL}/unsubscribe/${email}`);
                if (response.data.success) {
                    setUnsubscribeStatus('success');
                } else {
                    setUnsubscribeStatus('error');
                }
            } catch (error) {
                console.error('Error unsubscribing:', error);
                setUnsubscribeStatus('error');
            }
        };

        unsubscribeUser();
    }, [email]);

    return (
        <React.Fragment>
            <AppAppBar />
            <div className="unsubscribe-page">
                {unsubscribeStatus === '' ? (
                    <p>Loading...</p>
                ) : (
                    <div className="unsubscribe-content">
                        {unsubscribeStatus === 'success' ? (
                            <div className="unsubscribe-success">
                                <h2>Unsubscribed Successfully</h2>
                                <p>You have been successfully unsubscribed from our mailing list.</p>
                            </div>
                        ) : (
                            <div className="unsubscribe-error">
                                <h2>Unsubscribe Unsuccessful</h2>
                                <p>Sorry, we were unable to unsubscribe you at the moment.</p>
                                <p> This may be because you have already been unsubscribed or there was an error processing your request.</p>
                                <p>Please try again later </p>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <style jsx>{`
                .unsubscribe-page {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 90vh;
                    background-color: #f0f0f0;
                    background-image: url('/images/interior.jpg');
                    background-size: cover;
                    background-position: center; 
                    background-repeat: no-repeat;
                }

                .unsubscribe-content {
                    max-width: 600px;
                    padding: 20px;
                    background-color: #fff;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    text-align: center;
                }

                .unsubscribe-success {
                    color: #4caf50;
                }

                .unsubscribe-error {
                    color: #f44336;
                }
            `}</style>
        </React.Fragment>
    );
}

export default UnsubscribePage;